body {
  margin: 0;
}

body {
  --toastify-toast-width: 600px;
}
.Toastify__toast-body {
  padding: 16px;
  color: "#000";
}

.MuiCheckbox-root svg path {
  color: #cc0000;
}

@font-face {
  font-family: "SantanderHeadline";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/SantanderHeadline/SantanderHeadline-Regular.woff")
      format("woff"),
    url("./assets/fonts/SantanderHeadline/SantanderHeadline-Regular.woff2")
      format("woff2");
}

@font-face {
  font-family: "SantanderHeadline";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/SantanderHeadline/SantanderHeadline-It.woff")
      format("woff"),
    url("./assets/fonts/SantanderHeadline/SantanderHeadline-It.woff2")
      format("woff2");
}

@font-face {
  font-family: "SantanderHeadline";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/SantanderHeadline/SantanderHeadline-Bold.woff")
      format("woff"),
    url("./assets/fonts/SantanderHeadline/SantanderHeadline-Bold.woff2")
      format("woff2");
}

@font-face {
  font-family: "SantanderHeadline";
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/SantanderHeadline/SantanderHeadline-BoldIt.woff")
      format("woff"),
    url("./assets/fonts/SantanderHeadline/SantanderHeadline-BoldIt.woff2")
      format("woff2");
}

@font-face {
  font-family: "SantanderMicroText";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/SantanderMicroText/SantanderMicroText-Lt.woff")
      format("woff"),
    url("./assets/fonts/SantanderMicroText/SantanderMicroText-Lt.woff2")
      format("woff2");
}

@font-face {
  font-family: "SantanderMicroText";
  font-style: italic;
  font-weight: 300;
  src: url("./assets/fonts/SantanderMicroText/SantanderMicroText-LtIt.woff")
      format("woff"),
    url("./assets/fonts/SantanderMicroText/SantanderMicroText-LtIt.woff2")
      format("woff2");
}

@font-face {
  font-family: "SantanderMicroText";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/SantanderMicroText/SantanderMicroText.woff")
      format("woff"),
    url("./assets/fonts/SantanderMicroText/SantanderMicroText.woff2")
      format("woff2");
}

@font-face {
  font-family: "SantanderMicroText";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/SantanderMicroText/SantanderMicroText-It.woff")
      format("woff"),
    url("./assets/fonts/SantanderMicroText/SantanderMicroText-It.woff2")
      format("woff2");
}

@font-face {
  font-family: "SantanderMicroText";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/SantanderMicroText/SantanderMicroText-Bd.woff")
      format("woff"),
    url("./assets/fonts/SantanderMicroText/SantanderMicroText-Bd.woff2")
      format("woff2");
}

@font-face {
  font-family: "SantanderMicroText";
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/SantanderMicroText/SantanderMicroText-BdIt.woff")
      format("woff"),
    url("./assets/fonts/SantanderMicroText/SantanderMicroText-BdIt.woff2")
      format("woff2");
}

@font-face {
  font-family: "SantanderText";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/SantanderText/SantanderText-Lt.woff") format("woff"),
    url("./assets/fonts/SantanderText/SantanderText-Lt.woff2") format("woff2");
}

@font-face {
  font-family: "SantanderText";
  font-style: italic;
  font-weight: 300;
  src: url("./assets/fonts/SantanderText/SantanderText-LtIt.woff")
      format("woff"),
    url("./assets/fonts/SantanderText/SantanderText-LtIt.woff2") format("woff2");
}

@font-face {
  font-family: "SantanderText";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/SantanderText/SantanderText.woff") format("woff"),
    url("./assets/fonts/SantanderText/SantanderText.woff2") format("woff2");
}

@font-face {
  font-family: "SantanderText";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/SantanderText/SantanderText-It.woff") format("woff"),
    url("./assets/fonts/SantanderText/SantanderText-It.woff2") format("woff2");
}

@font-face {
  font-family: "SantanderText";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/SantanderText/SantanderText-Bd.woff") format("woff"),
    url("./assets/fonts/SantanderText/SantanderText-Bd.woff2") format("woff2");
}

@font-face {
  font-family: "SantanderText";
  font-style: italic;
  font-weight: 700;
  src: url("./assets/fonts/SantanderText/SantanderText-BdIt.woff")
      format("woff"),
    url("./assets/fonts/SantanderText/SantanderText-BdIt.woff2") format("woff2");
}

@font-face {
  font-family: "SantanderIcons";
  src: url("./assets/fonts/SantanderIcons/SantanderIcons.woff") format("woff"),
    url("./assets/fonts/SantanderIcons/SantanderIcons.woff2") format("woff2");
}

@font-face {
  font-family: "SantanderWebFont";
  src: url("./assets/fonts/SantanderIcons/SantanderWebFont.woff") format("woff"),
    url("./assets/fonts/SantanderIcons/SantanderWebFont.woff2") format("woff2");
}

.DayPickerInput input {
  font-family: SantanderMicroText;
  box-sizing: border-box;
  width: 275px;
  height: 57px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 40px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f0f0f0;
  background-image: url("./assets/img/ico-city-light-grey.svg");
  background-repeat: no-repeat;
  background-position: top 9px left 77px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  width: 60px;
  height: 48px;
  top: -4px;
  left: 0px;
  background-color: #cc0000;
  background-image: url("./assets/img/ico-circle-account-user-white.svg");
  background-repeat: no-repeat;
  background-position: top 12px left 18px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
}
input:checked + .slider {
  background-color: #f0f0f0;
  background-image: url("./assets/img/ico-circle-account-user-light-grey.svg");
  background-repeat: no-repeat;
  background-position: top 9px left 19px;
}
input:checked + .slider:before {
  background-image: url("./assets/img/ico-city-white.svg");
  background-repeat: no-repeat;
  background-position: top 12px left 18px;
  -webkit-transform: translateX(60px);
  -ms-transform: translateX(60px);
  transform: translateX(60px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 4px;
}
.slider.round:before {
  border-radius: 4px;
}

.dss-form-field__label {
  pointer-events: none;
}
.dss-cascader--active .dss-form-field__label {
  font-size: 14px;
  line-height: 21px;
  top: -12px;
}
.dss-form-field__label {
  background-color: #fff;
  color: #444;
  display: block;
  font-family: SantanderMicroText;
  font-size: 16px;
  font-weight: 400;
  left: 16px;
  line-height: 24px;
  padding-left: 2px;
  padding-right: 2px;
  position: absolute;
  top: 12px;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: top, font-size;
  -o-transition-property: top, font-size;
  transition-property: top, font-size;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  -o-transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
}
.dss-form-field__label--active {
  font-size: 14px;
  line-height: 21px;
  top: -12px;
}
.dss-form-field__input:focus ~ .dss-form-field__label {
  font-size: 14px;
  line-height: 21px;
  top: -12px;
}
.dss-cascader--open .dss-form-field__label {
  font-size: 14px;
  line-height: 21px;
  top: -12px;
}
.dss-dropdown--active .dss-form-field__label {
  font-size: 14px;
  line-height: 21px;
  top: -12px;
}
.dss-form-field--left-icon
  .dss-form-field__label:not(.dss-form-field__label--active) {
  left: 56px;
}
.dss-form-field--left-icon
  .dss-form-field__input:focus
  ~ .dss-form-field__label {
  left: 16px;
}
