.flatpickr-calendar {
  background-color: white;
  font-family: SantanderMicroText;
  box-shadow: 0 2px 8px -2px rgb(34 34 34 / 40%);
  border: 1px solid #ccc;
}
.flatpickr-months .flatpickr-month {
  background-color: white;
  padding-top: 5%;
  padding-bottom: 5%;
}

.flatpickr-weekdays {
  background-color: white;
  padding-top: 5%;
  padding-bottom: 1%;
}

span.flatpickr-weekday {
  background-color: white;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background-color: white;
  color: #ec0000;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 400;
  margin-right: 20%
}

.flatpickr-current-month input.cur-year {
  background-color: white;
  color: #ec0000;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 400;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
    left: 6%;
    top: 4%
}
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
    right: 41%;
    top: 4%
}
.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
    width: 24px;
    height: 24px;
}

